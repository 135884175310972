import globalAxios from "@/services/global-axios";
import store from "@/store";

import { useToast } from "vue-toastification";
const toast = useToast();

export default {
    getNewsLettersAll(){
        return globalAxios.get(`client/news-letters?paginate=0`, {
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('EGate-userInfo')).token}`
            }
        });
    },

    storeNewsletters(payload){
        store.dispatch('changeLoadingScreen' , true);
        return globalAxios
          .post('client/news-letters', {email:payload} , {
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('EGate-userInfo')).token}`
            }
          })
          .then((res) => {
            if (res.status == 200) {
              toast.success(`${res.data.message}`, {
                position: "top-right",
                transition: "slide",
                hideProgressBar: false,
                showIcon: true,
                timeout: 1000,
                showCloseButton: true,
                swipeClose: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(`${err.response.data.message}`, {
              position: "top-right",
              transition: "slide",
              hideProgressBar: false,
              showIcon: true,
              timeout: 3000,
              showCloseButton: true,
              swipeClose: true,
            });
          }).finally(() => {
            store.dispatch("Newsletters/getNewsletters");
            store.dispatch('changeLoadingScreen' , false)
          });
    },

    unsubscripeNewsletters(payload) {
        store.dispatch('changeLoadingScreen' , true);
        return globalAxios
          .patch(`client/news-letters/${payload.id}`, {status:0 , email:payload.email} , {
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('EGate-userInfo')).token}`
            }
          })
          .then((res) => {
            if (res.status == 200) {
              toast.success(`${res.data.message}`, {
                position: "top-right",
                transition: "slide",
                hideProgressBar: false,
                showIcon: true,
                timeout: 1000,
                showCloseButton: true,
                swipeClose: true,
              });
            }
          })
          .catch((err) => {
            toast.error(`${err.response.data.message}`, {
              position: "top-right",
              transition: "slide",
              hideProgressBar: false,
              showIcon: true,
              timeout: 3000,
              showCloseButton: true,
              swipeClose: true,
            });
          }).finally(() => {
            store.dispatch("Newsletters/getNewsletters");
            store.dispatch('changeLoadingScreen' , false)
          });
      },
}