import globalAxios from "@/services/global-axios";
import NewsLetterApi from "@/apis/NewLetter";

export const Newsletters = {
    namespaced: true,
    state: () => ({
        newsletters: [],
    }),

    getters: {
        newsletters(state) {
            return state.newsletters;
        }
    },

    mutations: {
        GET_NEWSLETTERS(state, data) {
            state.newsletters = data;
        },
    },


    actions: {
        async getNewsletters({ commit }) {
            NewsLetterApi.getNewsLettersAll().then((res) => {
                if (res?.data?.code == 200) {
                    commit('GET_NEWSLETTERS', res.data.items.data)
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
        async storeNewlettersData({commit} , payload){
            NewsLetterApi.storeNewsletters(payload).then((res) => {
                console.log('success');
            })
            .catch((err) => {
                console.log(err);
            });
        },
        async unsubscripeNewsletters({commit} , news){
            NewsLetterApi.unsubscripeNewsletters(news).then((res) => {
                if (res?.data?.code == 200) {
                    commit('GET_NEWSLETTERS', res.data.items.data)
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    },

}


