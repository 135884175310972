<template>
  <section class="homeMainSlider" v-if="banners.length && banners">
    <swiper v-bind="options" :modules="modules" class="mySwiper">
      <swiper-slide v-for="(banner, index) in banners" :key="index" class="slider-image-holder specificClassImageSlider">
        <v-img class="styleImageSpecific" :src="banner.image"></v-img>
        <div class="button-holder">
          <router-link :to="'/productPage/' + banner.product_id" class="router-link-holder">{{ $t('home.ShopNow') }}</router-link>
        </div>
      </swiper-slide>
    </swiper>
  </section>
  <section v-else>
    <Skeleton />
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper";
import { Skeleton } from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"
export default {
  data() {
    return {
      options: {
        slidesPerView: 1,
        spaceBetween: 30,
        activeIndex: 1,
        loop: true,
        pagination: {
          clickable: true,
        },
        centeredSlides: true,
        speed: 300,
        direction: "horizontal",
        zoom: true,
        autoplay: {
          delay: 5000,
        },
        fadeEffect: {
          crossFade: true,
        },
      },
    };
  },
  props: ['banners'],
  components: {
    Swiper,
    SwiperSlide,
    Skeleton
  },

  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  }

};
</script>

<style scoped lang="scss">
p {
  color: #000;
}
.slider-image-holder {
  position: relative;

  .button-holder {
    position: absolute;
    top: 95%;
    left: 10%;
    transform: translate(-10%, -50%);

    .router-link-holder {
      background: #303958;
      padding: 12px 2vw;
      border-radius: 10px 10px 0px 0px;
      text-transform: capitalize;
      text-decoration: none;
      color: #fff;
      font-weight: 600;
      font-size: 30px;
    }
  }
}
</style>
