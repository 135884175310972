<template>
  <nav class="menu" v-if="$i18n.locale == 'en'">
    <div class="mobile-menu-head">
      <div class="go-back">
        <v-icon icon="mdi-chevron-left"></v-icon>
      </div>
      <div class="current-menu-title"></div>
      <div class="mobile-menu-close">&times;</div>
    </div>
    <ul class="menu-main header-main-links" style="direction:ltr;">

      <li class="menu-item-has-children">
        <router-link to="/" class="text-white">{{ $t("home.home") }}</router-link>
      </li>
      <li class="menu-item-has-children">
        <router-link to="/categories" class="text-white">{{ $t('home.AllCategories') }}</router-link>
      </li>
      <li class="menu-item-has-children">
        <router-link to="/about" class="text-white">{{ $t('home.AboutUs') }}</router-link>
      </li>
      <li class="menu-item-has-children">
        <router-link to="/faq" class="text-white">{{ $t('home.FAQs') }}</router-link>
      </li>
      <li class="menu-item-has-children">
        <router-link to="/deals" class="text-white">{{ $t('home.Deals') }}</router-link>
      </li>
      <li class="menu-item-has-children">
        <router-link to="/contact" class="text-white">{{ $t('home.ContactUs') }}</router-link>
      </li>

    </ul>
  </nav>
  <nav class="menu" v-else>
    <div class="mobile-menu-head">
      <div class="go-back">
        <v-icon icon="mdi-chevron-left"></v-icon>
      </div>
      <div class="current-menu-title"></div>
      <div class="mobile-menu-close">&times;</div>
    </div>
    <ul class="menu-main header-main-links" style="direction:rtl;">

      <li class="menu-item-has-children">
        <router-link to="/" class="text-white">{{ $t("home.home") }}</router-link>
      </li>
      <li class="menu-item-has-children">
        <router-link to="/categories" class="text-white">{{ $t('home.AllCategories') }}</router-link>
      </li>
      <li class="menu-item-has-children">
        <router-link to="/about" class="text-white">{{ $t('home.AboutUs') }}</router-link>
      </li>
      <li class="menu-item-has-children">
        <router-link to="/faq" class="text-white">{{ $t('home.FAQs') }}</router-link>
      </li>
      <li class="menu-item-has-children">
        <router-link to="/deals" class="text-white">{{ $t('home.Deals') }}</router-link>
      </li>
      <li class="menu-item-has-children">
        <router-link to="/contact" class="text-white">{{ $t('home.ContactUs') }}</router-link>
      </li>

    </ul>
  </nav>
</template>

<script>
export default {};
</script>

<style></style>
