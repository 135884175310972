<template>
  <div :class="$i18n.locale">
    <div class="searchBar-holder">
      <div id="search-box">
        <div class="wrapper">
          <v-form class="search" @submit.prevent="searchProduct">
            <v-autocomplete
              :label="$t('home.SearchByProductName')"
              @keyup="getProducts"
              @keydown="searchProductEnter"
              v-model="key"
              single-line
              hide-details
              density="compact"
              type="text"
              class="searchText"
              :items="dataSearch"
            ></v-autocomplete>
            
            <button class="searchButton">
              <v-icon icon="mdi-magnify"></v-icon>
            </button>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import home from '@/services/home';
import { useToast } from "vue-toastification";
export default {
  mounted(){
    this.getProducts();
  },

  data(){
    return {
      key:null,
      product_id:null,
      dataSearch:[]
    }
  },
  methods: {
    async getProducts(){
      try{
        const res = await home.getProductsData();
        if(this.$i18n.locale == 'en'){
          let filteredArray = res.data.items.data.map((e) => {
            return e.name_en;
          });
          this.dataSearch = filteredArray; 
        }else{
          let filteredArray = res.data.items.data.map((e) => {
            return e.name_ar;
          });
          this.dataSearch = filteredArray;
        }
               
      }catch(e){
        console.log(e);
      }
    },
    async searchProductEnter(e){
      try{
        if(e.key == 'Enter'){
          const toast = useToast();
          if(this.key != null){
            const res = await home.searchProductsData(this.key);
            if(res.data.items.data.length > 0){
              this.$router.push('/ProductPage/'+res.data.items.data[0].id);
            }else{
              let msg = this.$t("home.NoProductFound");
              toast.error(`${msg}`, {
                position: "top-right",
                transition: "slide",
                hideProgressBar: false,
                showIcon: true,
                timeout: 3000,
                showCloseButton: true,
                swipeClose: true,
              });
            }
          }
        }
        
      }catch(e){
        console.log(e);
      }
    },
    async searchProduct(e){
      try{
        const toast = useToast();
        if(this.key == null){
          let msg = this.$t('home.PleaseEnterProductNameToSearch');
          toast.warning(`${msg}`, {
            position: "top-right",
            transition: "slide",
            hideProgressBar: false,
            showIcon: true,
            timeout: 3000,
            showCloseButton: true,
            swipeClose: true,
          });
        }else{
          const res = await home.searchProductsData(this.key);
          if(res.data.items.data.length > 0){
            this.$router.push('/ProductPage/'+res.data.items.data[0].id);
          }else{
            let msg = this.$t("home.NoProductFound");
            toast.error(`${msg}`, {
              position: "top-right",
              transition: "slide",
              hideProgressBar: false,
              showIcon: true,
              timeout: 3000,
              showCloseButton: true,
              swipeClose: true,
            });
          }
        }
        
      }catch(e){
        console.log(e);
      }
        
    }
  },
  watch:{
    '$i18n.locale'(newVal, oldVal) {
      this.getProducts();
    },
  }
};
</script>

<style lang="scss" scoped>
label{
  color:#fff !important;
}
.searchBar-holder {
  .selectData {
    cursor: pointer;
    text-transform: capitalize;
    //text-align: center;
    //width: 30%;
    color: $header-back;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background: transparent !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
    //position: relative;
    padding: 0 5px;

    @media (max-width: 767px) {
      margin: 20px 0;
    }
  }
  .icon-holder {
    position: absolute;
    right: 8%;
    bottom: 0;
    top: 0;
    color: #000;
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search {
    border: 2px solid $second-color;
    border-radius: 5px;
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #303958;
    color: #fff;
    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  input {
    padding: 0px;
    font-size: 18px;
    background: white;
    // border-right: 2px solid $second-color;
    padding: 10px;
  }

  .searchText {
    text-indent: 5px;
    border-radius: 5px 0 0 5px;
    width: 70%;
    @media (max-width: 499.98px) {
      width: 100%;
    }
    @media (min-width: 500px) and (max-width: 766.98px) {
      width: 60%;
    }
    @media (min-width: 767px) and (max-width: 992px) {
      width: 50%;
    }
  }

  .searchButton {
    color: #000;
    background-color: $second-color;
    cursor: pointer;
    width: 8%;
    padding: 8px 0px;
    @media (max-width: 767px) {
      width: auto;
      padding: 8px;
      width: 100%;
      margin: auto;
    }
  }
}
</style>
