import globalAxios from "@/services/global-axios";
import store from "@/store";

import { useToast } from "vue-toastification";
const toast = useToast();

export default {
    getBalance(){
        store.dispatch('changeLoadingScreen', true);
        return globalAxios.get(`client/charge-wallets/balance`, {
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('EGate-userInfo')).token}`
            }
        }).finally(() => {
            store.dispatch('changeLoadingScreen', false)
        });
    },
}