import globalAxios from "@/services/global-axios";
import SettingApi from "@/apis/Setting";

export const Settings = {
    namespaced: true,
    state: () => ({
        settings: [],
    }),

    getters: {
        settings(state) {
            return state.settings;
        }
    },

    mutations: {
        GET_SETTINGS(state, data) {
            state.settings = data;
        },
    },


    actions: {
        async getSettings({ commit }) {
            SettingApi.getSettingsAll().then((res) => {
                if (res.status == 200) {
                    commit('GET_SETTINGS', res.data.items.data)
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    },

}


