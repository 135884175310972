<template>
  <div class="hold-all-data">
    <h5 class="text-gray-dark mb-2">{{ $t("home.Categories") }}</h5>
    <div class="page-wrapper headerCategories" v-if="$i18n.locale == 'en'">
      <nav class="menuCategories" v-if="categories.length && categories">
        <div class="side-menu">
          <ul>
            <li
              class="menu-item"
              v-for="(category, index) in categories"
              :key="index"
            >
              <div class="hold-link d-flex justify-space-between align-center">
                <router-link
                  :to="'/categoryPage/' + category.id"
                  class="text-dark"
                >
                  <span title="Men">
                    {{
                      $i18n.locale == "en" ? category.name_en : category.name_ar
                    }}
                  </span>
                </router-link>
                <v-icon icon="mdi-chevron-right"></v-icon>
              </div>
              <div class="submenu" v-if="category?.childrens?.length">
                <ul>
                  <li>
                    <div class="list-item d-flex bd-highlight">
                      <div class="p-2 flex-grow-1 bd-highlight">
                        <h4 class="title">
                          {{
                            $i18n.locale == "en"
                              ? category.name_en
                              : category.name_ar
                          }}
                        </h4>
                        <ul class="menu-main">
                          <li
                            class="mx-3"
                            v-for="(child, index) in category.childrens"
                            :key="index"
                          >
                            <router-link :to="'/categoryPage/' + child.id">
                              <span class="text-dark">
                                {{
                                  $i18n.locale == "en"
                                    ? child.name_en
                                    : child.name_ar
                                }}
                              </span>
                            </router-link>
                          </li>
                        </ul>
                      </div>

                      <div class="p-2 bd-highlight">
                        <img :src="category.cover" alt="" class="w-100" />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <!-- Repeat for other categories -->
          </ul>
        </div>
      </nav>
    </div>
    <div class="page-wrapper headerCategoriesAr" v-else>
      <nav class="menuCategoriesAr" v-if="categories.length && categories">
        <div class="side-menu">
          <ul>
            <li
              class="menu-item"
              v-for="(category, index) in categories"
              :key="index"
            >
              <div class="hold-link d-flex justify-space-between align-center">
                <v-icon icon="mdi-chevron-left"></v-icon>
                <router-link
                  :to="'/categoryPage/' + category.id"
                  class="text-dark"
                >
                  <span title="Men">
                    {{
                      $i18n.locale == "en" ? category.name_en : category.name_ar
                    }}
                  </span>
                </router-link>
                
              </div>
              <div class="submenuAr" v-if="category?.childrens?.length">
                <ul>
                  <li>
                    <div class="list-item d-flex bd-highlight">
                      <div class="p-2 flex-grow-1 bd-highlight">
                        <h4 class="title">
                          {{
                            $i18n.locale == "en"
                              ? category.name_en
                              : category.name_ar
                          }}
                        </h4>
                        <ul class="menu-main">
                          <li
                            class="mx-3"
                            v-for="(child, index) in category.childrens"
                            :key="index"
                          >
                            <router-link :to="'/categoryPage/' + child.id">
                              <span class="text-dark">
                                {{
                                  $i18n.locale == "en"
                                    ? child.name_en
                                    : child.name_ar
                                }}
                              </span>
                            </router-link>
                          </li>
                        </ul>
                      </div>

                      <div class="p-2 bd-highlight">
                        <img :src="category.cover" alt="" class="w-100" />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <!-- Repeat for other categories -->
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["categories"],
};
</script>

<style lang="scss" scoped>
.side-menu ul {
  list-style-type: none;
  padding: 0;
}

.side-menu li {
  position: relative; /* Context for absolute positioning */
}
.side-menu li.mx-3 {
  @media (max-width: 992px) {
    margin: 0 !important;
    padding: 0 !important;
    line-height: 25px;
  }
}

.submenu {
  display: none;
  position: absolute;
  left: 101%; /* Align the submenu to the right of the menu item */
  top: 0;
  z-index: 1000; /* Ensure it stacks on top */
}
.submenuAr {
  display: none;
  position: absolute;
  right: 101%; /* Align the submenu to the right of the menu item */
  top: 0;
  z-index: 1000; /* Ensure it stacks on top */
}

.menu-item {
  display: block !important;
  border: 2px solid #bfcbe3;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 5px;
  line-height: 50px;
  cursor: pointer;
}
.menu-item:hover .submenu {
  display: block; /* Show submenu on hover */
}
.menu-item:hover .submenuAr {
  display: block; /* Show submenu on hover */
}
.submenu {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  min-width: 35rem;
  @media (max-width: 992px) {
    left: 20%;
    padding: 10px;
    font-size: 13px;
  }
}

.submenuAr {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  min-width: 35rem;
  @media (max-width: 992px) {
    right: 20%;
    padding: 10px;
    font-size: 13px;
  }
}

.menu-item:hover .submenu {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
  opacity: 1;
  border: 1px solid #003399;
  border-top: 5px solid #003399;
  cursor: default;
  position: absolute;
  z-index: 500;
  background-color: #ffffff !important;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  padding: 20px 30px;
  transition: all 0.5s ease;
  border-radius: 10px;
  min-width: 40rem;
  max-height: 25rem;
  overflow-y: auto;
  img {
    border-radius: 15px;
  }
}

.menu-item:hover .submenuAr {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
  opacity: 1;
  border: 1px solid #003399;
  border-top: 5px solid #003399;
  cursor: default;
  position: absolute;
  z-index: 500;
  background-color: #ffffff !important;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  padding: 20px 30px;
  transition: all 0.5s ease;
  border-radius: 10px;
  min-width: 40rem;
  max-height: 25rem;
  overflow-y: auto;
  img {
    border-radius: 15px;
  }
}

p {
  color: #000;
  font-size: 14px;
}

ul > li .title {
  font-size: 20px;
  color: #ea4636;
  font-weight: 500;
  line-height: 1;
  padding: 10px 0;
}

@media (max-width: 992px) {
  .list-item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menu-item:hover .submenu{
    min-width: fit-content !important;
  }
  .submenu{
    top: 80%;
  }
  .menu-item:hover .submenuAr{
    min-width: fit-content !important;
  }
  .submenuAr{
    top: 80%;
  }
}
</style>