import globalAxios from "./global-axios";

export default {
    getBanners() {
        return globalAxios.get('client/banners?paginate=0')
    },
    getCategories() {
        return globalAxios.get('client/categories?paginate=0&isParent=true')
    },
    homeProducts() {
        return globalAxios.get(`client/products?hasOffer=true&paginate=0`)
    },
    homeProductsFeatured() {
        return globalAxios.get(`client/products?featured=true&paginate=0`)
    },
    homeProductsTopRated() {
        return globalAxios.get(`client/products?top_rated=true&paginate=0`)
    },
    homeProductsTopReviewed() {
        return globalAxios.get(`client/products?top_reviewed=true&paginate=0`)
    },
    homeProductsAllProductsDiscount() {
        return globalAxios.get(`client/all-offers?paginate=0`)
    },
    getFeaturedCategories() {
        return globalAxios.get(`client/categories/featured?paginate=0`)
    },
    getProductsSpecial() {
        return globalAxios.get(`client/special-offers?paginate=0`)
    },
    getProductsBuyXGetY() {
        return globalAxios.get(`client/buy-x-get-y?paginate=0`)
    },
    getProductsBuyXGetGift() {
        return globalAxios.get(`client/buy-x-get-geft?paginate=0`)
    },
    getProductsBasketOffers() {
        return globalAxios.get(`client/basket-offers?paginate=0`)
    },
    getSettings() {
        return globalAxios.get(`settings?paginate=0`);
    },
    getFaqs() {
        return globalAxios.get(`settings/faqs`);
    },
    storeContactUsForm(payload) {
        return globalAxios.post(`contact`, payload);
    },
    getProductsData(){
        return globalAxios.get(`client/products?paginate=0`);
    },
    searchProductsData(params){
        return globalAxios.get(`client/products?name_en=${params}`);
    },
    getBrands(){
        return globalAxios.get(`client/brands?paginate=0`);
    }
}