<template>
  <v-app :class="$i18n.locale" class="app-holder">
    <v-main>
      <MainLayout />
    </v-main>
    <LoadingDataFetch v-if="loadingPageData == true" />
  </v-app>
</template>

<script>
import LoadingDataFetch from "@/components/shared/LoadingDataFetch.vue";
import MainLayout from "@/layouts/MainLayout";
import guest from '@/services/guest';
// import myMixin from "@/mixins.js";
import { useMeta } from "vue-meta";
export default {
  // mixins: [myMixin],
  components: {
    MainLayout,
    LoadingDataFetch,
  },
  name: "App",

  data: () => ({
    page: 1,
  }),
  methods: {
    async getCategories() {
      await this.$store.dispatch("Categories/getCategories");
    },
    async getCartItems() {
        await this.$store.dispatch("cart/getCartProducts");
    },
    async getWishlistItems() {
      await this.$store.dispatch("wishlist/getWishlistItems");
    },
    async getSettings() {
      await this.$store.dispatch("Settings/getSettings");
    },
    async getNewsletters() {
      await this.$store.dispatch("Newsletters/getNewsletters");
    },
    async checkIfuserGuest() {
      try {
        if (this.isLoggedIn) {
          console.log('you are authenticated');
        } else if (localStorage.getItem('guest-token')) {
          console.log('you are logged in as a guest');
        } else {
            await guest.post('guest/generate-token');
        }
      } catch (e) {
        console.log(e.message);
      }
    },
  },
  mounted() {
    this.checkIfuserGuest();
    this.getSettings();
    setTimeout(()=>{
      this.getCategories();
      this.getCartItems();
    },2000);
    if(this.isLoggedIn){
      setTimeout(()=>{
        this.getWishlistItems();
      },2000);
      this.getNewsletters();
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters['Auth/isAuthenticated'];
    },
    loadingPageData() {
      return this.$store.getters['Auth/LoginNow'];
    },
  },
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "en", amp: true },
    });
  },
};
</script>
<style lang="scss">
.ifInWidthMobile p{
  color: #fff !important;
}
.styleData .counter{
    height: 100%;
}
.styleRender p{
    color: #5F5E58 !important;
}
.pu-skeleton {
  background-color:rgb(227 227 227) !important;
  line-height:35 !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

body {
  background: #F0F2F5 0% 0% no-repeat padding-box !important;
}

::-moz-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: transparent;
  border-radius: 10px;
}

::-moz-scrollbar-track {
  box-shadow: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #262626;
  border-radius: 10px;
}

::-moz-scrollbar-thumb {
  background: #262626;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $main-color;
}

::-moz-scrollbar-thumb:hover {
  background: $main-color;
}

.specificClassImageSlider .styleImageSpecific img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 0;
  margin: auto;
}

.valueParagraph p{
    color: #000 !important;
}
.valueParagraphPrivacy p{
    color: #000 !important;
}
.descriptionParagraph p{
    color: #000 !important;
}
p.descriptionParagraph{
    color: #000 !important;
}
 p.descriptionAboutUs{
    color: #fff !important;
  }
  p.descriptionPrivacyPolicy{
    color: #000 !important;
  }
  .App-wrapper{
    min-height: 0vh !important;
  }
</style>
