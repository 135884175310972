export default {
  // nav
  home: "الرئيسية",
  suppliers: "الموردين",
  corporat: "تسجيل الشركات",
  buyer: "المشتري",
  contactUs: "اتصل بنا",
  mySupply: "ماي سبلاي",
  allRightsReserved: " جميع الحقوق محفوظة",
  bestDeal: "أفضل الصفقات",
  dailyOffers: "العروض اليومية",
  monthlyOffers: "العروض الخاصه",
  about: "عن الشركة",
  vision: "رؤية الشركة",
  message: "رسالة الشركة",
  myPrinciples: "مبادئ الشركة",
  registerForTheNewsletter: "التسجيل في النشرة الإخبارية",
  beTheFirstToGetExclusiveOffersAndCoupons:
    "كن أول من يحصل على عروض وكوبونات حصرية",
  subscribe: "الإشتراك",
  email: "عنوان بريد الكتروني",
  kuwait: "الكويت",
  cart: "سلة المشتريات",
  KWD: "الدينار الكويتي",
  EGP: "الجنيه المصري",
  showAll: "عرض الكل",
  noData: "لا يوجد بيانات ",
  tilNow: "حتي الان",
  noDataTill: "لا يوجد بيانات حتي الان",
  All: "الكل",
  vendors: "تسجيل الموردين",
  pageNoteFound1: "الصفحة التي تبحث عنها ليست موجودة.",
  you: "انت",
  ok: "أوافق",
  privacyPolicy: "سياسة الخصوصيه",
  OutOfStock:"غير موجود بالمخزن",
  RateThisProduct:'تقييم هذا المنتج',
  Out_Of:'من',
  AddAReview:'أضف تقييم',
  YourReview:'مراجعة رأيك',
  Done:'تم',
  MyAccount:"حسابي",
  AccountInformation:"معلومات الحساب",
  ContactInformation:"معلومات التواصل",
  Edit:"تعديل",
  Newsletters:"الاخبار",
  YouAreSubscribed:"انت مشترك معنا في النشرات الاخبارية",
  YouArentSubscribed:"انت لست مشترك معنا في النشرات الاخبارية",
  AddressBook:"دليل العناوين",
  DefaultBillingAddress:"عنوان الفواتير الافتراضي",
  Address:"العنوان",
  EditAddress:"تعديل العنوان",
  Unsubscripe:"إلغاء الاستراك",
  UpdateContact:"تعديل بيانات التواصل",
  FirstName:"الاسم الاول",
  LastName:"الاسم الثاني",
  Email:"البريد الإلكتروني",
  Mobile:"رقم الموبايل",
  ChangePassword:"تغيير الباسورد",
  NewPassword:"الباسورد الجديد",
  ConfirmNewPassword:"تأكيد الباسورد الجديد",
  LogOut:"تسجيل خروج",
  Logout:"تسجيل خروج",
  Cancel:"إلغاء",
  AreYouSureYouWantToLogout:"هل أنت متأكد من أنك تريد الخروج ؟",
  Orders:"طلباتي",
  NotYetDispatched:"الطبات التي لم يتم إيصالها بعد",
  CancelledOrders:"الطلبات الملغية",
  PlacedIn:"الموضوعة في",
  Reset:"إعادة ضبط",
  BUYITAGAIN:"اعادة شرائها",
  MyWallet:"محفظتي",
  Receivables:"المستحقات",
  Payments:"المدفوعات",
  Charges:"الرسوم",
  Balance:"الرصيد",
  Redeem:"استرداد",
  Charge:"رصيد",
  Deposit:"إيداع",
  ChargeValue:"قيمة الرصيد",
  removeall:"مسح الكل",
  ProceedToShippingAddress:"المتابعة إلي عنوان الشحن",
  ViewCart:"عرض السلة",
  TOTAL:"المجموع الكلي",
  PRODUCT:"المنتج",
  CONTINUESHOPPING:"متابعة التسوق",
  Payment:"الدفع",
  Information:"المعلومات",
  ChooseLocalPoint:"اختر النقطة المحلية",
  PickLocalPoint:"اختر النقطة المحلية",
  NoLocalPointsYet:"لا نوجد نقط محلية",
  ChooseFastChargingOrRegularCharging:"اختر شحن سريع او شحن عادي",
  OrderRecieveDate:"موعد تاريخ الاستلام",
  RegularCharging:"شحن عادي",
  FastCharging:"شحن سريع",
  PRICE:"السعر",
  QUANTITY:"الكمية",
  SUBTOTAL:"المجموع الفرعي",
  TrackOrder:"تتبع الطلب",
  SelectDateFrom:"اختر التاريخ من",
  SelectDateTo:"إلي تاريخ",
  EstimatedShippingTime:"الوقت المتوقع للتبضع:",
  UpdateContactInOrder:"تعديل معلومات التواصل في الطلب",
  UpdateContact:"تعدبل معلومات التواصل",
  Password:"كلمة المرور",
  ConfirmPassword:"تأكيد كلمة المرور",
  Login:"تسجيل دخول",
  Register:"إنشاء حساب",
  Contact:"معلومات التواصل",
  Days:"ايام",
  Selectdate:"اختر تاريخ",
  ReturnToCart:"الرجوع إلي السلة",
  ContinueToCheckout:"متابعة عملية الشراء",
  ToCompleteCheckoutOrderPleaseRegisterOr:"لمتابعة عملية الشراء يرجي القيام بإنشاء حساب أو ",
  Change:"تغيير",
  ShipTo:"شحن إلي",
  SailPoint:"نقطة محلية",
  Method:"طريقة الدفع",
  PickOrderInLocalPoint:"سوف يتم استلام الطلب في النقطة المحددة",
  Apply:"تطبيق",
  ReturnToShipping:"العودة للتسوق",
  CompleteOrder:"إنهاء عملية الشراء",
  UpdateSailPoint:"تعديل اختيار النقطة المحلية",
  UpdateAddressBook:"تعديل اختيار العنوان",
  FillInformation:"املأ البيانات",
  CardNumber:"رقم البطاقة",
  Confirm:"تأكيد",
  UplaodBankTransferFile:"رفع صورة تحويل البنكي",
  HaveAPromocode:"هل لديك بطاقة خصم ؟",
  ThankyouforshoppingonEGate:"شكرا لك علي التسوق في موقع اي جيت",
  YourOrderHasBeenPlacedSuccessfully:"طلبك تم التأكيد عليه بنجاح",
  TRACKORDER:"تتبع الطلب",
  Return:"استرجاع",
  OrderItems:"منتجات الطلب",
  OrderStatus:"حالة الطلب",
  ReturnProducts:"استرجاع المنتجات",
  ReturnProduct:"استرجاع المنتج",
  Areyousureyouwanttoreturnthisproduct:"هل أنت متأكد من أنك تريد استرجاع هذا المنتج؟",
  Wewillcontactyouandoneofyouragentswillreceivetheproductbackin5businessdays:"سوف نتواصل مع احد العملاء الخاصين بك لكي يسترجع المنتج خلال خمسة أيام عمل",
  VIEWINVOICE:"عرض الفاتورة",
  Confirmation:"تم تأكيد الطلب برقم",
  YoullReceiveAConfirmationEmailWithYourOrderNumberShortly:"سوف تستلم رسالة تأكيد برقم الطلب علي بريدك الإلكتروني",
  YoullGetShippingAndDeliveryUpdatesByEmail:"سوف يتم تبليغك بكل جديد بالنسبة لطلبك من الدليفري والشحن عن طريق البريد الإلكتروني",
  CustomerInformation:"معلومات العميل",
  ContactInformation:"معلومات التواصل",
  PickupPoint:"نقطة الالتقاط",
  ShippingMethod:"طريقة الشحن",
  PaymentMethod:"طريقة الدفع",
  TotalPayments:"مجموع المدفوعات",
  YourOrderIsPending:"طلبك معلق",
  OrderUpdates:"تحديثات الطلب",
  OrderSerial:"رقم الطلب",
  PRINTINVOICE:"طباعة الفاتورة",
  CustomerName:"اسم العميل",
  CustomerEmail:"البريد الإلكتروني للعميل",
  CustomerPhone:"رقم هاتف العميل",
  PaymentInformation:"معلومات الدفع",
  PaymentStatus:"حالة الدفع",
  PaymentType:"نوع عملية الدفع",
  PaymentCurrency:"عملة الدفع",
  ShippingInformation:"معلومات الشحن",
  DeliveryFees:"رسوم التوصيل",
  OrderStatus:"حالة الطلب",
  MoreAbout:"عرض اكثر عن",
  SortBy:"الترتيب",
  Result:"النتيجة",
  Showing:"إظهار",
  Brands:"المحلات",
  TopRated:"الاعلي تقييم",
  Featured:"المتميزة",
  TopReviewed:"الاعلي مراجعة",
  PickupInformation:"معلومات حول نقطة التقاط الطلب",
  OrderTotal:"المجموع الكلي للطلب",
  TotalShippingFees:"المجموع الكلي لرسوم الشحن",
  TotalDiscount:"المجموع الكلي للخصم",
  TotalPrice:"المجموع الكلي للاسعار",
  Home:"الرئيسية",
  ProductName:"اسم المنتج",
  BuyerInformation:"معلومات الشاري",
  AreYouSureYouWantToContinue:"هل أنت متأكد من أنك تريد المتابعة ؟",
  Continue:"متابعة",
  Price:"السعر",
  Qty:"الكمية",
  AddFile:"إضافة صورة البنك",
  CVV:"CVV",
  ExpiryDate:"تاريخ الانتهاء",
  to:"إلي",
  Subtotal:"المجموع الفرعي",
  Total:"المجموع الكلي",
  ShippingFees:"مصاريف الشحن",
  MyWishList:"قائمة المفضلات",
  AddAllToCart:"إضافة القائمة إلي السلة",
  UPDATEWISHLIST:"تحديث قائمة المفضلات",
  YourWishlistIsEmpty:"قائمة المفضلات لديك فارغة",
  LooksLikeYouHaveNotAddedAnything:"يبدو أنك لم تضيف شيئا في قائمة مفضلاتك , تصفخ قائمة الفئات الاعلي",
  Remove:"إزالة",
  Addedtocart:"تم إضافته للسلة",
  CategoryName:"اسم الفئة",
  MyReturns:"مسترجعاتي",
  Order:"الطلب",
  Status:"الحالة",
  Date:"التاريخ",
  Country:"البلد",
  State:"المحافظة",
  City:"المدينة",
  ShowInvoice:"عرض الفاتورة",
  EditAddressBook:"تعديل العنوان",
  SaveChanges:"حفظ التعديلات",
  termsConditions: "الشروط و الاحكام",
  faq: "الأسئلة الشائعة",
  siteName: "فريق دعم HumHumm",
  siteSupport: "الرد غالبا يكون خلال ساعة",
  hiThere: "مرحبا",
  helpYou: "كيف يمكننى مساعدتك ؟",
  startCaht: "ابدأ المحادثه",
  navSuppliers: 'الشركاء',
  clients: 'العملاء',
  consumerCategory: 'فئة المستهلك',
  corporateCategory: 'فئة الشركة',
  Categories:'الأصناف',
  AllCategories:'كل الأصناف',
  AboutUs:'عن الموقع',
  FAQs:'الاسئلة الشائعة',
  ContactUs:'تواصل معنا',
  DiscountProducts:'المنتجات التي لها خصم',
  in_stock:'في المحل',
  out_of_stock:'غير موجود بالمحل',
  MinimumQuantity:'أقل كمية',
  Featured:"منتجات مميزة",
  Top_Rated:"أعلي تقييم",
  On_Sale:"أعلي مبيعات",
  Special_Offer:"عرض خاص",
  Available:"متاح",
  Already_Sold:"تم بيع",
  Hurry_Up : "أسرع ! العرض سوف ينفذ خلال",
  Wait_For_Special_Offer: "انتظر العرض الخاص",
  save:'وفر',
  MyAccount:"حسابي",
  Orders: "الطلبات",
  WishList:"المنتجات المفضلة",
  Returns:"الطلبات المسترجعة",
  CustomerCare:"اهتمامات العميل",
  Find_It_Fast:"ابحث عن منتجك بسرعة",
  Got_questions:"عندك أسئلة؟ اتصل بنا 24/7",
  Sign_Up_To_Newsletter: "اشترك حتي يصلك الأخبار الجديدة عن موقعنا",
  And_Receive: "واستلم عروض من خلال اول تسوق لك",
  SignUp:"اشترك",
  EmailAddress:"بريدك الإلكتروني",
  Deals:"عروض",
  BankNumber:"رقم البنك",
  Amount:"القيمة",
  ID:"الرقم",
  UserID:"رقم المستخدم",
  Username:"المستخدم",
  ShopNow:"تسوق الآن",
  RateThisBasket:"تقييم هذة السلة",
  MoneyTransfered:"حالة فلوس التحويل",
  CreatedAt:"تم إنشاؤه",
  PleaseAddFileTransferBank:"من فضلك ضع صورة تحويل البنك",
  Youmustbeauthenticated:"يحب أن تكون مستخدم مسجل",
  Youmustchooseatleastoneproducttoreturn:"يجب عليك ان تختار علي الاقل منتج واحد لاستعادته !",
  PleaseEnterProductNameToSearch:"من فضلك اكتب اسم منتج للبحث",
  minimumquantityisminimumorderquantity:"اقل كمية هي اقل كمية من الطلب => ",
  YourCartIsEmpty:"السلة الخاصة بك فارغة",
  Pleasefillrateanditsreview:"من فضلك أملأ التقييم ورأيك عن المنتج",
  NoProductFound:"لا يوجد منتج",
  PleaseEnterDepositChargeValue:"من فضلك ادخل قيمة الإيداع !",
  ContactInOrderUpdated:"معلومات التواصل تم تعديلها",
  UserAddedSuccessfully:"المستخدم تمت إضافته بنجاح",
  BankFileAddedSuccessfully:"صورة البنك تمت إضافتها بنجاح",
  CouponCodeAccepted:"كوبون الخصم تم قبوله",
  SailPointOfOrderUpdatedSuccessfully:"نقطة الالتقاط تم تعديلها بنجاح",
  OrderStoredSuccessfully:"الطلب تم حفظه بنجاح",
  OrderReturnedSuccessfully:"الطلب تم استرجاعه بنجاح",
  PasswordUpdatedSuccessfully:"الرقم السري تم تعديله بنجاح",
  SuccessfullyLoggedOut:"تم الخروج بنجاح",
  Submit:"تأكيد",
  BuyBasketWith:"اشتري هذه السلة مع ",
  ProductsFor:"منتجات ب",
  Products:"منتجات",
  ContactUpdatedSuccessfully:"معلومات التواصل تم تعديلها بنجاح",
  BuyXGetYOffer:"اشتري x احصل علي y",
  basket:"عرض السلة",
  SignIn:"تسجيل دخول",
  OR:"أو",
  SignUpWithYourSocialAccounts:"إنشاء حساب باستخدام حساباتك الاجتماعية",
  AlreadyHaveAnAccount:"هل لديك حساب بالفعل ؟",
  Clear:"إفراغ",
  RememberMe:"تذكرني",
  DonttHaveAnAccount:"هل لديك حساب بالفعل ؟ ",
  SignInWithGoogle:"تسجيل دخول باستخدام جوجل",
  SignInWithApple:"تسجيل دخول باستخدام آبل",
  SignInWithFacebook:"تسجيل دخول باستخدام فيسبوك",
  SignUp:"إنشاء حساب",
  BuyXGetGiftOffer:"اشتري x احصل علي هدية",
  SpecialOffers:"عروض خاصة",
  BasketsOffer:"عروض السلة",
  Buy:"اشتري",
  Withdraw:"ادخل قيمة السحب",
  AccountNumber:"رقم الحساب",
  Iban:"Iban",
  bankName:"اسم البنك",
  ShippingType:"نوع التوصيل",
  Gets:"تحصل علي",
  Details:"التفاصيل",
  Description:"الوصف",
  MoreInfo:"معلومات أكثر",
  Reveiws:"التقييمات",
  BuyItNow:"اشتريه الآن",
  Shipping:"الشحن",
  Within2Days:"خلال يومين",
  SoldBy:"تم بيعه من قبل",
  ReturnPolicy:"سياسة الاسنرجاع",
  ShippingPolicy:"سياسة الشحن",
  AuthorizedClients:"خدماتنا لعملائنا",
  ContactInfo:"معلومات التواصل",
  SendUs:"أرسل لنا رسالة",
  WeAreAlwaysHere:"نحن هنا دائما لمساعدتك",
  YourName:"اسمك",
  Email:"بريدك الالكتروني",
  Phone:"الهاتف المحمول",
  Subject:"عنوان الرسالة",
  Message:"الرسالة",
  Send:"إرسال",
  Brand:"الماركة",
  Type:"النوع",
  registerThank:'شكرا على انشاء حساب',
  SearchByProductName:'البحث من خلال اسم المنتج',
  NoReviewYet:"لا توجد تقييمات بعد",

  //cart

  itemName: "زيتون يوناني",
  total: "الاجمالي",
  shopping: "سلة المشتريات",
  purchase: "اذهب للشراء",
  noData: "لا يوجد بيانات",
  noDataMatch: "لا يوجد بيانات مطابقة",
  supplier: "اسم المورد",
  priceUnit: "دينار كويتي",
  purchaseCart: "سلة الشراء",
  product: "المنتج",
  price: "السعر",
  quantity: "الكمية",
  UpdateDelivery: "تحديث بيانات التوصيل",
  couponDiscount: "كوبون خصم",
  addCoupon: "اضف كوبون الخصم",
  totalCart: "اجمالي السلة",
  discount: "الخصم",
  delivery: "التوصيل",
  free: "مجاني",
  checkout: "الذهاب للدفع",
  selectOption: "من فضلك قم بالاختيار",
  quoteName: "اسم الطلب",
  message: "ارسل الى المورد",
  submit: "ارسل ",
  cancel: "الغاء",
  noProducts: "لا يوجد منتجات",
  addedToCart: "تم اضافة المنتج للسله",
  addToCart: " اضف المنتج للسلة",
  removedFromCart: "تم حذف المنتج",
  noCartProducts: "لايوجد منتجات فى السلة",
  updateCart: "تم تحديث السلة",
  enableButton: "حذف الكوبون",
  proceAfterDisc: "السعر بعد الخصم",
  currency: "العملة",
  cartCount: "يوجد عدد ",
  cartInCart: "من المنتجات فى السلة",
  cartSubTotal: "السعر الكلى لمنتجات السلة",
  contShopping: "تابع عملية التسوق",
  viewCart: "سلة المشتريات",
  success: "تمت العمليه بنجاح",
  items: "منتج",
  fillData: "من فضلك املأ البيانات",
  next: "التالى",
  addedToWishlist: "تم اضافة المنتج لقائمة المفضلة",
  viewWishlist: "قائمة المفضلة",
  wishlist: "قائمة المفضلة",
  search: "بحث",
  checkFees: "معرفة رسوم الشحن",
  deliveryData: "معلومات التواصل",
  deleiveryFees: "مصاريف الشحن",
  asc: "من الاقل للأعلى ",
  desc: "من الاعلى للأقل",
  sortBy: "تصفيه ب",
  selectPickupAddress: "اختر عنوان استلام للمورد ",
  viewAll: "عرض الكل",
  readMore: "قراءة المذيد",
  readLess: "قراءة أقل",
  add:'اضافه',
  filter:'فلتر',
  standQuantity:'الكميه المطلوبه من المنتج',
  couponExist:'الكود مستخدم بالفعل',
  validCoupons:'الكوبونات المفعله'
};
