<template>
  <footer>
    <TopFooter />
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="4">

          

          <div class="" v-if="$i18n.locale == 'en'">
            <div class="logo" style="direction:ltr;">
              <router-link to="/">
                <v-img width="100" src="@/assets/images/logo.png"></v-img>
              </router-link>
            </div>
          </div>

          <div class="" v-else>
            <div class="logo" style="direction:rtl;">
              <router-link to="/">
                <v-img width="100" src="@/assets/images/logo.png"></v-img>
              </router-link>
            </div>
          </div>

          <div class="additionl-data mt-11">
            <h2>{{ $t('home.ContactUs') }}</h2>
          </div>
          <p class="text-gray-dark">
            {{addressWebsite[0]?.value}}
          </p>

          <div v-if="$i18n.locale == 'en'">
            <div class="media aligned-row mt-11" style="direction:ltr">
              <div class="image-holder">
                <v-img width="50" src="@/assets/images/footer/Group56027.png"></v-img>
              </div>
              <div class="info mx-2">
                <p class="text-gray gray-only">{{ $t('home.Got_questions') }}</p>
                <p class="text-gray-dark">{{contactPhoneHotline[0]?.value}}</p>
              </div>
            </div>
          </div>
          
          <div v-else>
            <div class="media aligned-row mt-11" style="direction:rtl">
              <div class="info mx-2">
                <p class="text-gray gray-only">{{ $t('home.Got_questions') }}</p>
                <p class="text-gray-dark" style="direction:ltr">{{contactPhoneHotline[0]?.value}}</p>
              </div>
              <div class="image-holder">
                <v-img width="50" src="@/assets/images/footer/Group56027.png"></v-img>
              </div>
            </div>
          </div>
          
          
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="5" v-if="$i18n.locale == 'en'">
          <div class="titleHolder">
            <h2>{{ $t('home.Find_It_Fast') }}</h2>
          </div>

          <v-row style="direction:ltr;">
            <v-col cols='6' md='6' sm='12'>
              <ul>
                <li v-for="(category, index) in mainCategories" :key="index">
                  <router-link :to="'/categoryPage/'+category.id" class="text-gray" >
                   {{ category.name_en }}
                  </router-link>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="5" v-else>
          <div class="titleHolder">
            <h2>{{ $t('home.Find_It_Fast') }}</h2>
          </div>

          <v-row style="direction:rtl;">
            <v-col cols='6' md='6' sm='12'>
              <ul>
                <li v-for="(category, index) in mainCategories" :key="index">
                  <router-link :to="'/categoryPage/'+category.id" class="text-gray" >
                   {{ category.name_ar }}
                  </router-link>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="3">
          <div class="titleHolder">

            <h2>{{ $t('home.CustomerCare') }}</h2>
          </div>
          <ul>
            <li>
              <router-link class="text-gray" to="/profile/account">{{ $t('home.MyAccount') }}</router-link>
            </li>
            <li>
              <router-link class="text-gray" to="/profile/orders">{{ $t('home.Orders') }}</router-link>
            </li>
            <li>
              <router-link class="text-gray" to="/profile/wishlist">{{ $t('home.WishList') }}</router-link>
            </li>
            <li>
              <router-link class="text-gray" to="/profile/returns">{{ $t('home.Returns') }}</router-link>
            </li>
            <li>
              <router-link class="text-gray" to="/faq">{{ $t('home.FAQs') }}</router-link>
            </li>
            <li>
              <router-link class="text-gray" to="/privacyPolicy">{{ $t('home.privacyPolicy') }}</router-link>
            </li>
            <li>
              <router-link class="text-gray" to="/terms">{{ $t('home.termsConditions') }}</router-link>
            </li>
            <li>
              <router-link class="text-gray" to="/contact">{{ $t('home.ContactUs') }}</router-link>
            </li>
          </ul>
        </v-col>

      </v-row>
      <div class="text-center pt-3">
        <hr />
        <p class="text-gray pt-3">© 2024 Copyrights by E-Gate. All Rights Reserved. Made With <v-icon icon="mdi-heart"></v-icon> <a href="https://dev.fabrica.com.eg/"> <span class="styleFooterText">Dominic for developments and investment L.L.C-FZ</span></a></p>
      </div>
    </v-container>
  </footer>
</template>

<script>
import home from '@/services/home';
import TopFooter from "./TopFooter";
export default {
  mounted(){
    this.getMainCategories();
    this.getMainSettings();
  },
  data(){
    return{
      mainCategories:[],
      settings:[],
      contactPhoneHotline:[],
      addressWebsite:[]
    }
  },
  components: {
    TopFooter,
  },
  methods:{
    async getMainCategories(){
      const res = await home.getCategories();
      this.mainCategories = res.data.items.data;
    },
    async getMainSettings(){
      const response = await home.getSettings();
      let contactPhoneHotlineArr = [];
      contactPhoneHotlineArr = response.data.items.data.filter((el) => {
        return el.key == 'main_contact_hotline';
      });
      this.contactPhoneHotline = contactPhoneHotlineArr;

      let AddressArr = [];
      if(this.$i18n.locale == 'en'){
        AddressArr = response.data.items.data.filter((el) => {
          return el.key == 'website_address_en';
        });
        this.addressWebsite = AddressArr;
      }else{
        AddressArr = response.data.items.data.filter((el) => {
          return el.key == 'website_address_ar';
        });
        this.addressWebsite = AddressArr;
      }
      
    }
  }
};
</script>

<style lang="scss" scoped>
.styleFooterText{
  color:#674df0;
}
footer {
  background: $header-back;
  color: #fff;
}

.titleHolder {
  padding: 20px 0
}

a {
  line-height: 40px
}

.text-gray,
.text-gray-dark {
  color: #fff !important;
}

.gray-only {
  color: $gray !important;
}
</style>
