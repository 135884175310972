import globalAxios from "@/services/global-axios";
import store from "@/store";

import { useToast } from "vue-toastification";
const toast = useToast();

export default {
    getShippingFeesData(sailPointId){
        store.dispatch('changeLoadingScreen', true);
        return globalAxios.get(`client/shipping/calc-fee?sail_point_id=${sailPointId}`, {
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('EGate-userInfo')).token}`
            }
        }).finally(() => {
            store.dispatch('changeLoadingScreen', false)
        });
    },
    getShippingFeesType(data){
        store.dispatch('changeLoadingScreen', true);
        return globalAxios.get(`client/shipping/calc-fee-type-cost/${data.sailPointId}?fees=${data.fees}&type=${data.type}`, {
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('EGate-userInfo')).token}`
            }
        }).finally(() => {
            store.dispatch('changeLoadingScreen', false)
        });
    }
}