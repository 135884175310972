import globalAxios from "@/services/global-axios";

export default {
    getSettingsAll(){
        return globalAxios.get(`settings?paginate=0`, {
            headers: {
              'Accept': 'application/json'
            }
        });
    }
}