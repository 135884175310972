<template>
  <div class="top-footer-wapper" :class="$i18n.locale">
    <v-container v-if="$i18n.locale == 'en'">
      <v-row class="aligned-row">
        <v-col cols="12" sm="12" md="7">
          <v-row class="aligned-row" justify="center" align="center">
            <v-col cols="12" md="6" sm="12">
              <span
                ><font-awesome-icon icon="fa-solid fa-paper-plane" /> {{ $t('home.Sign_Up_To_Newsletter') }}</span
              >
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <span>{{ $t('home.And_Receive') }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="5">
          <v-form @submit.prevent="storeNewsLetter">
            <div class="input-holder">
              <input
                class="enterEmail"
                v-model="newsLetterEmail"
                type="text"
                :placeholder="$t('home.EmailAddress')"
                style="direction:ltr;"
              />
              <button class="mailBtn" type="submit" v-if="isLoggedIn" style="pointer-events:auto">{{ $t('home.SignUp') }}</button>
              <button class="mailBtn" type="submit" v-else style="pointer-events:none">{{ $t('home.SignUp') }}</button>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row class="aligned-row">
        <v-col cols="12" sm="12" md="5">
          <v-form @submit.prevent="storeNewsLetter">
            <div class="input-holder">
              <input
                class="enterEmail"
                type="text"
                v-model="newsLetterEmail"
                :placeholder="$t('home.EmailAddress')"
                style="direction:rtl;"
              />
              <button class="mailBtn" type="submit" v-if="isLoggedIn" style="pointer-events:auto">{{ $t('home.SignUp') }}</button>
              <button class="mailBtn" type="submit" v-else style="pointer-events:none">{{ $t('home.SignUp') }}</button>
            </div>
          </v-form>
        </v-col>

        <v-col cols="12" sm="12" md="7">
          <v-row class="aligned-row" justify="center" align="center">
            
            <v-col cols="12" md="6" sm="12">
              <span>{{ $t('home.And_Receive') }}</span>
            </v-col>

            <v-col cols="12" md="6" sm="12">
              <span
                > {{ $t('home.Sign_Up_To_Newsletter') }} <font-awesome-icon icon="fa-solid fa-paper-plane" /></span
              >
            </v-col>

          </v-row>
        </v-col>



      </v-row>
    </v-container>
  </div>
</template>

<script>
import globalAxios from '@/services/global-axios';

export default {
  data(){
    return {
      newsLetterEmail:null
    }
  },
  methods:{
    async storeNewsLetter(){
      try{
        await this.$store.dispatch("Newsletters/storeNewlettersData", this.newsLetterEmail);
        this.newsLetterEmail = null;
      }catch(e){
        console.log(e);
      }
    }
  },
  computed:{
    isLoggedIn: function () {
      return this.$store.getters['Auth/isAuthenticated'];
    },
  }
};
</script>

<style scoped lang="scss">
.top-footer-wapper {
  padding: 10px;
  background-color: $second-color;
  font-size: 16px;
  color: #000;
  svg {
    font-size: 22px !important;
  }
}

.input-holder {
  position: relative;
  .mailBtn {
    position: absolute;
    right: -5px;
    top: 0;
    bottom: 0;
    background: $main-color;
    color: #fff;
    border-radius: 0 20px 20px 0;
    padding: 10px 25px;
  }
  .enterEmail {
    background: #fff;
    padding: 10px 15px;
    border-radius: 20px;
    width: 100%;

    &:active,
    &:hover,
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}
.ar {
  .input-holder {
    position: relative;
    .mailBtn {
      left: 0;
      border-radius: 20px 0 0 20px;
      position: absolute;
    padding: 10px 25px;
    right: auto;

    }
  }
}
</style>
