import globalAxios from "@/services/global-axios";
import ShippingFeesApi from "@/apis/ShippingFeesApi";

export const ShippingFees = {
    namespaced: true,
    state: () => ({
        shipping_fees: 0,
    }),

    getters: {
        shipping_fees(state) {
            return state.shipping_fees;
        },
    },

    mutations: {
        GET_SHIPPINGFEES(state, data) {
            state.shipping_fees = data;
        },
    },


    actions: {
        async getShippingFees({ commit } , payload) {
            ShippingFeesApi.getShippingFeesData(payload).then((res) => {
                if (res?.data?.code == 200) {
                    commit('GET_SHIPPINGFEES', res.data.items.shipping_fee);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
        restoreShippingFees({ commit } , payload){
            commit('GET_SHIPPINGFEES', payload);
        },
        async getShippingTypeFees({ commit } , payload) {
            ShippingFeesApi.getShippingFeesType(payload).then((res) => {            
                if (res?.data?.code == 200) {
                    commit('GET_SHIPPINGFEES', res.data.items.shipping_fee);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
    },

}


