import globalAxios from "@/services/global-axios";
import BalanceApi from "@/apis/Balance";

export const Balance = {
    namespaced: true,
    state: () => ({
        balance: 0,
    }),

    getters: {
        balance(state) {
            return state.balance;
        },
    },

    mutations: {
        GET_BALANCE(state, data) {
            state.balance = data;
        },
    },

    actions: {
        async getBalanceRedeem({ commit }) {
            BalanceApi.getBalance().then((res) => {
                if (res?.status == 200) {
                    commit('GET_BALANCE', res?.data?.balance);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
    },

}


