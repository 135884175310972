export default {
    // nav
    home: "Home",
    suppliers: "Suppliers",
    corporat: "Corporat",
    buyer: "Buyer",
    contactUs: "Contact us",
    mySupply: "MySupply",
    allRightsReserved: "All Rights Reserved",
    bestDeal: "Best Deals",
    dailyOffers: "Daily Offers",
    monthlyOffers: "Special Offers",
    about: "About us",
    vision: "Vision",
    message: "Message",
    myPrinciples: "My Principles",
    registerForTheNewsletter: "Register for the newsletter",
    beTheFirstToGetExclusiveOffersAndCoupons: "Be the first to get exclusive offers and coupons",
    subscribe: "Subscribe",
    email: "Email Address",
    kuwait: "Kuwait",
    cart: "Cart",
    KWD: "KWD",
    EGP: "EGP",
    showAll: "Show all",
    noData: 'No Data Available',
    tilNow: 'Till Now',
    noDataTill: 'No Data Available',
    All: 'All',
    vendors:'Clienteles',
    pageNoteFound1: "THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST.",
    you:'You',
    ok:'Ok',
    privacyPolicy:'Privacy & Policy',
    termsConditions:'Terms & Conditions',
    faq:'FAQ',
    siteName:'HumHumm Support',
    siteSupport:'Typical response within one hour',
    hiThere:'Hi there',
    helpYou:'How can I help you?',
    startCaht:'Start Chat',
    navSuppliers:'PARTNERS',
    clients:'Clients',
    consumerCategory:'Consumer Category',
    corporateCategory:'Corporate Category',
    Categories:'Categories',
    AllCategories:'All Categories',
    AboutUs:'About Us',
    OutOfStock:"Out Of Stock",
    RateThisProduct:'Rate This Product',
    Out_Of:'Out Of',
    AddAReview:'Add A Review',
    YourReview:'Your Review',
    Done:'Done',
    MyAccount:"My Account",
    AccountInformation:"Account Information",
    ContactInformation:"Contact Information",
    Edit:"Edit",
    Newsletters:"Newsletters",
    YouAreSubscribed:"You Are Subscribed To Our Newsletter.",
    YouArentSubscribed:"You Aren’t Subscribed To Our Newsletter",
    AddressBook:"Address Book",
    DefaultBillingAddress:"Default Billing Address",
    FAQs:'FAQs',
    Address:"Address",
    EditAddress:"Edit Address",
    Unsubscripe:"Unsubscripe",
    UpdateContact:"Update Contact",
    FirstName:"First Name",
    LastName:"Last Name",
    Password:"Password",
    ConfirmPassword:"Confirm Password",
    Email:"E-mail",
    Login:"Login",
    Register:"Register",
    ShipTo:"Ship To",
    SailPoint:"Sail Point",
    Method:"Method",
    PickOrderInLocalPoint:"Pick Order In Local Point",
    Apply:"Apply",
    ReturnToShipping:"Return To Shipping",
    Return:"Return",
    OrderItems:"Order Items",
    CompleteOrder:"Complete Order",
    UpdateSailPoint:"Update Sail Point",
    UpdateAddressBook:"Update Address Book",
    FillInformation:"Fill Information",
    CardNumber:"Card Number",
    CVV:"CVV",
    ExpiryDate:"Expiry Date",
    Mobile:"Mobile",
    ChangePassword:"Change Password",
    NewPassword:"New Password",
    ConfirmNewPassword:"Confirm New Password",
    Confirm:"Confirm",
    UplaodBankTransferFile:"Uplaod Bank Transfer File",
    HaveAPromocode:"HaveAPromocode?",
    ThankyouforshoppingonEGate:"Thank you for shopping on E-Gate!",
    YourOrderHasBeenPlacedSuccessfully:"Your Order Has Been Placed Successfully",
    TRACKORDER:"TRACK ORDER",
    VIEWINVOICE:"VIEW INVOICE",
    Confirmation:"Confirmation",
    YoullReceiveAConfirmationEmailWithYourOrderNumberShortly:"You’ll Receive A Confirmation Email With Your Order Number Shortly.",
    YoullGetShippingAndDeliveryUpdatesByEmail:"You ’ll Get Shipping And Delivery Updates By Email.",
    CustomerInformation:"Customer Information",
    ContactInformation:"Contact Information",
    PickupPoint:"Pick up Point",
    ShippingMethod:"Shipping Method",
    PaymentMethod:"Payment Method",
    TotalPayments:"Total Payments",
    YourOrderIsPending:"Your Order Is Pending",
    OrderUpdates:"Order Updates",
    AddFile:"Add File",
    LogOut:"Log Out",
    Logout:"Logout",
    Cancel:"Cancel",
    AreYouSureYouWantToLogout:"Are You Sure You Want To Logout ?",
    Orders:"Orders",
    NotYetDispatched:"Not Yet Dispatched",
    CancelledOrders:"Cancelled Orders",
    SelectDateFrom:"Select Date From",
    SelectDateTo:"Select Date To",
    Days:"Days",
    OrderStatus:"Order Status",
    RateThisBasket:"Rate This Basket",
    ReturnProducts:"Return Products",
    ReturnProduct:"Return Product",
    Areyousureyouwanttoreturnthisproduct:"Are you sure you want to return this product?",
    Wewillcontactyouandoneofyouragentswillreceivetheproductbackin5businessdays:"We will contact you and one of your agents will receive the product back in 5 business days",
    to:"to",
    OrderSerial:"Order Serial",
    PRINTINVOICE:"PRINT INVOICE",
    CustomerName:"Customer Name",
    CustomerEmail:"Customer Email",
    CustomerPhone:"Customer Phone",
    PaymentInformation:"Payment Information",
    BuyerInformation:"Buyer Information",
    PaymentStatus:"Payment Status",
    PaymentType:"Payment Type",
    PaymentCurrency:"Payment Currency",
    ShippingInformation:"Shipping Information",
    DeliveryFees:"Delivery Fees",
    OrderStatus:"Order Status",
    AreYouSureYouWantToContinue:"Are You Sure You Want To Continue",
    Continue:"Continue",
    Home:"Home",
    MoreAbout:"More About",
    SortBy:"Sort By",
    Result:"Result",
    Showing:"Showing",
    Brands:"Brands",
    TopRated:"TopRated",
    Featured:"Featured",
    TopReviewed:"TopReviewed",
    PickupInformation:"Pick up Information",
    OrderTotal:"Order Total",
    TotalShippingFees:"Total Shipping Fees",
    TotalDiscount:"Total Discount",
    TotalPrice:"Total Price",
    ProductName:"Product Name",
    Price:"Price",
    Qty:"Qty",
    Subtotal:"Subtotal",
    Total:"Total",
    ShippingFees:"Shipping Fees",
    MyWishList:"My Wish List",
    AddAllToCart:"Add All To Cart",
    UPDATEWISHLIST:"Update Wishlist",
    YourWishlistIsEmpty:"Your Wishlist Is Empty",
    LooksLikeYouHaveNotAddedAnything:"Looks Like You Have Not Added Anything To Your Wishlist. Go Ahead & Explore Top Categories",
    EstimatedShippingTime:"Estimated Shipping Time :",
    Remove:"Remove",
    Addedtocart:"Added to cart",
    CategoryName:"Category Name",
    MyReturns:"My Returns",
    Order:"Order",
    Status:"Status",
    Date:"Date",
    Country:"Country",
    State:"State",
    City:"City",
    EditAddressBook:"Edit Address Book",
    SaveChanges:"Save Changes",
    PlacedIn:"Placed In",
    Reset:"Reset",
    BUYITAGAIN:"BUY IT AGAIN",
    ShowInvoice:"Show Invoice",
    TrackOrder:"Track Order",
    ContactUs:'Contact Us',
    DiscountProducts:'Discount Products',
    MyWallet:"My Wallet",
    Receivables:"Receivables",
    Payments:"Payments",
    Charges:"Charges",
    Balance:"Balance",
    Redeem:"Redeem",
    Charge:"Charge",
    Deposit:"Deposit",
    ChargeValue:"Charge Value",
    removeall:"Remove All",
    ProceedToShippingAddress:"Proceed To Shipping Address",
    ViewCart:"View Cart",
    Payment:"Payment",
    Information:"Information",
    ChooseLocalPoint:"Choose Local Point",
    PickLocalPoint:"Pick Local Point",
    NoLocalPointsYet:"No Local Points Yet",
    ChooseFastChargingOrRegularCharging:"Choose Fast Charging Or Regular Charging",
    OrderRecieveDate:"Order Recieve Date",
    RegularCharging:"Regular Charging",
    FastCharging:"Fast Charging",
    TOTAL:"TOTAL",
    PRODUCT:"PRODUCT",
    PRICE:"PRICE",
    QUANTITY:"QUANTITY",
    SUBTOTAL:"SUBTOTAL",
    CONTINUESHOPPING:"CONTINUE SHOPPING",
    in_stock:'in stock',
    out_of_stock:'out of stock',
    MinimumQuantity:'Minimum Quantity',
    Featured:"Featured",
    Top_Rated:"Top_Rated",
    On_Sale:"On_Sale",
    Special_Offer:"Special Offer",
    Available:"Available",
    Already_Sold:"Already Sold",
    AlreadyHaveAnAccount:"Already Have An Account ?",
    Hurry_Up : "Hurry Up! Offer Ends In",
    Wait_For_Special_Offer: "Wait For Special Offer",
    save:"save",
    MyAccount:"My Account",
    Orders: "Orders",
    WishList:"WishList",
    Returns:"Returns / Exchange",
    Change:"Change",
    UpdateContactInOrder:"Update Contact In Order",
    UpdateContact:"Update Contact",
    Contact:"Contact",
    Selectdate:"Select Date",
    ReturnToCart:"Return To Cart",
    ContinueToCheckout:"Continue To Checkout",
    ToCompleteCheckoutOrderPleaseRegisterOr:"To Complete Checkout Order Please Register Or",
    CustomerCare:"Customer Care",
    Find_It_Fast:"Find It Fast",
    Got_questions:"Got questions? Call us 24/7!",
    Sign_Up_To_Newsletter: "Sign Up To Newsletter",
    SignUp:"Sign Up",
    EmailAddress:"Email Address",
    Details:"Details",
    Type:"Type",
    MoreInfo:"More Info",
    Reveiws:"Reveiws",
    ReturnPolicy:"Return Policy",
    ShippingPolicy:"Shipping Policy",
    Brand:"Brand",
    BuyItNow:"Buy It Now",
    Shipping:"Shipping",
    Within2Days:"Within 2 Days",
    SoldBy:"Sold By",
    Clear:"Clear",
    SignUpWithYourSocialAccounts:"Sign Up With Your Social Accounts",
    AuthorizedClients:"Authorized Clients Of Our Services",
    ContactInfo:"Contact Info",
    SendUs:"Send Us A Message",
    WeAreAlwaysHere:"We Are Always Here To Help You",
    YourName:"Your Name",
    Email:"E-mail",
    Phone:"Phone",
    Subject:"Subject",
    Deals:"Deals",
    BuyXGetYOffer:"Buy X Get Y Offer",
    BuyXGetGiftOffer:"Buy X Get Gift Offer",
    SpecialOffers:"Special Offers",
    BasketsOffer:"Baskets Offer",
    Buy:"Buy",
    Amount:"Amount",
    ID:"ID",
    Gets:"Gets",
    Withdraw:"Enter Withdraw Value",
    AccountNumber:"Account Number",
    BankNumber:"Bank Number",
    Iban:"Iban",
    bankName:"Bank Name",
    ShippingType:"Shipping Type",
    Message:"Message",
    Send:"Send",
    Description:"Description",
    And_Receive: "And Receive Offer For First Shopping",
    registerThank:'Thanks for Registeration',
    SearchByProductName:"Search By Product Name",
    NoReviewYet:"No Review Yet",
    PleaseAddFileTransferBank:"Please Add File Transfer Bank",
    Youmustbeauthenticated:"You must be authenticated",
    Youmustchooseatleastoneproducttoreturn:"You must choose at least one product to return!",
    PleaseEnterProductNameToSearch:"Please Enter Product Name To Search",
    minimumquantityisminimumorderquantity:"minimum quantity is minimum order quantity => ",
    YourCartIsEmpty:"Your Cart Is Empty",
    Pleasefillrateanditsreview:"Please fill rate and it's review",
    NoProductFound:"No Product Found",
    PleaseEnterDepositChargeValue:"Please Enter Deposit Charge Value !",
    ContactInOrderUpdated:"Contact In Order Updated",
    UserAddedSuccessfully:"User Added Successfully",
    UserID:"User ID",
    Username:"Username",
    MoneyTransfered:"Money Transfered",
    ShopNow:"Shop Now",
    CreatedAt:"Created At",
    BankFileAddedSuccessfully:"Bank File Added Successfully",
    CouponCodeAccepted:"Coupon Code Accepted",
    SailPointOfOrderUpdatedSuccessfully:"Sail Point Of Order Updated Successfully",
    OrderStoredSuccessfully:"Order Stored Successfully",
    OrderReturnedSuccessfully:"Order Returned Successfully",
    PasswordUpdatedSuccessfully:"Password Updated Successfully",
    SuccessfullyLoggedOut:"Successfully Logged Out",
    ContactUpdatedSuccessfully:"Contact Updated Successfully",
    basket:"basket",
    SignIn:"Sign In",
    RememberMe:"Remember Me",
    DonttHaveAnAccount:"Don't Have An Account? ",
    Submit:"Submit",
    BuyBasketWith:"Buy Basket With",
    ProductsFor:"Products For",
    Products:"Products",
    SignInWithGoogle:"Sign In With Google",
    SignInWithApple:"Sign In With Apple",
    SignInWithFacebook:"Sign In With Facebook",
    OR:"OR",
    SignUp:"Sign Up",

    //cart 
    itemName: "Greek olives",
    total: "Total",
    shopping: "Shopping Cart",
    purchase: "Checkout",
    noData: "No Data",
    noDataMatch: "No Matching Data",
    supplier: "Supplier name",
    priceUnit: "KWD",
    purchaseCart: "Purchase Cart",
    product: "Product",
    price: "Price",
    quantity: "Quantity",
    UpdateDelivery: "Update delivery Data",
    couponDiscount: "Coupon Discount",
    addCoupon: "Add a discount coupon",
    totalCart: "Total Cart",
    discount: "Discount",
    delivery: "Delivery",
    free: "Free",
    checkout: "Checkout",
    selectOption: "Please select an option",
    quoteName: "Enter Quote Name",
    message: "Message To supplier",
    cancel: "Cancel",
    submit: "Send Request",
    noProducts: "No Products",
    addedToCart: "Product added to cart.",
    addToCart: "Add to cart",
    removedFromCart: "Product removed from cart",
    noCartProducts: "No Products In cart",
    updateCart: "Cart Updated",
    enableButton: "Remove Coupon",
    proceAfterDisc: "Price After Discount",
    currency: "Currency",
    cartCount: "There are ",
    cartInCart: "in your cart",
    cartSubTotal: "Cart Subtotal",
    contShopping: "Continue Shopping",
    viewCart: "View Cart",
    success: "Success",
    items: "Items",
    fillData: "Please Fill Data",
    next: "Next",
    addedToWishlist: "Product added to Wishlist.",
    viewWishlist: "View Wishlist",
    wishlist: "Wishlist",
    search: "Search",
    checkFees: "Check Delivery Fees",
    deleiveryFees: "Delivery Fees",
    asc:'Low Price To High Price',
    desc:'High Price To Low Price',
    sortBy:'Sort By',
    selectPickupAddress:'Select Pickup Address',
    viewAll:"View All",
    readMore:'Read More',
    readLess:'Read Less',
    add:'Add',
    filter:'Filter By',
    standQuantity:'Quantity of this product',
    couponExist:'Coupon Already Exist',
    validCoupons:'Valid Coupons'
};